import { useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement);

function EmiCalculator() {
  const defValues = {
    principal: 4500000,
    roi: 8.5,
    term: 300,
  };

  const [input, setInput] = useState(defValues);
  const [installments, setInstallments] = useState([]);
  const [emi, setEmi] = useState(0);
  const [sums, setSums] = useState({ totalEmi: 0, totalPrincipal: 0, totalInterest: 0, totalExtra: 0 });
  const [extraPayments, setExtraPayments] = useState(Array(defValues.term).fill(0));
  const [replicateExtra, setReplicateExtra] = useState(false);
  const [replicateAmount, setReplicateAmount] = useState(0);
  const [startDate, setStartDate] = useState(1);
  const [endDate, setEndDate] = useState(defValues.term);
  const [reducedMonths, setReducedMonths] = useState(0);

  const handleChangeInput = (event) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeExtraPayment = (index, value) => {
    const updatedExtraPayments = [...extraPayments];
    updatedExtraPayments[index] = Number(value);
    setExtraPayments(updatedExtraPayments);
  };

  const handleReplicateExtraChange = (event) => {
    setReplicateExtra(event.target.checked);
    if (event.target.checked) {
      const updatedExtraPayments = extraPayments.map((_, index) =>
        index + 1 >= startDate && index + 1 <= endDate ? replicateAmount : 0
      );
      setExtraPayments(updatedExtraPayments);
    }
  };

  const handleReplicateAmountChange = (event) => {
    const amount = Number(event.target.value);
    setReplicateAmount(amount);
    if (replicateExtra) {
      const updatedExtraPayments = extraPayments.map((_, index) =>
        index + 1 >= startDate && index + 1 <= endDate ? amount : 0
      );
      setExtraPayments(updatedExtraPayments);
    }
  };

  const handleDateChange = (event, type) => {
    const value = Number(event.target.value);
    if (type === 'start') {
      setStartDate(value);
      if (replicateExtra) {
        const updatedExtraPayments = extraPayments.map((_, index) =>
          index + 1 >= value && index + 1 <= endDate ? replicateAmount : 0
        );
        setExtraPayments(updatedExtraPayments);
      }
    } else {
      setEndDate(value);
      if (replicateExtra) {
        const updatedExtraPayments = extraPayments.map((_, index) =>
          index + 1 >= startDate && index + 1 <= value ? replicateAmount : 0
        );
        setExtraPayments(updatedExtraPayments);
      }
    }
  };

  function calculate() {
    const principal = Number(input.principal);
    const roi = Number(input.roi);
    const term = Number(input.term);

    const monthlyRate = roi / 1200;
    const numerator = principal * monthlyRate * Math.pow(1 + monthlyRate, term);
    const denominator = Math.pow(1 + monthlyRate, term) - 1;
    const emiValue = numerator / denominator;
    setEmi(emiValue.toFixed(2));
    generateInstallments(principal, monthlyRate, term, emiValue);
  }

  const generateInstallments = (principal, monthlyRate, term, emi) => {
    const installmentsArray = [];
    let remainingPrincipal = principal;
    let totalEmi = 0;
    let totalPrincipal = 0;
    let totalInterest = 0;
    let totalExtra = 0;
    let months = 0;

    for (let month = 1; month <= term; month++) {
      const interestForMonth = remainingPrincipal * monthlyRate;
      const principalForMonth = emi - interestForMonth + extraPayments[month - 1];
      remainingPrincipal -= principalForMonth;

      totalEmi += emi;
      totalPrincipal += principalForMonth;
      totalInterest += interestForMonth;
      totalExtra += extraPayments[month - 1];
      months = month;

      installmentsArray.push({
        month,
        emi: emi.toFixed(2),
        principal: principalForMonth.toFixed(2),
        interest: interestForMonth.toFixed(2),
        extraPayment: extraPayments[month - 1].toFixed(2),
        balance: remainingPrincipal.toFixed(2),
      });

      if (remainingPrincipal < 0) {
        break;
      }
    }

    setInstallments(installmentsArray);
    setSums({
      totalEmi: totalEmi.toFixed(2),
      totalPrincipal: totalPrincipal.toFixed(2),
      totalInterest: totalInterest.toFixed(2),
      totalExtra: totalExtra.toFixed(2),
    });
    setReducedMonths(term - months);
  };

  const pieData = {
    labels: ['Principal', 'Interest'],
    datasets: [
      {
        data: [sums.totalPrincipal, sums.totalInterest],
        backgroundColor: ['#36A2EB', '#FF6384'],
        borderColor: ['#fff', '#fff'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="emi-calculator">
      <input onChange={handleChangeInput} name="principal" type="number" value={input.principal} placeholder="Principal" /><br />
      <input onChange={handleChangeInput} name="roi" type="number" value={input.roi} placeholder="Rate of Interest" /><br />
      <input onChange={handleChangeInput} name="term" type="number" value={input.term} placeholder="Term (months)" /><br />
      <p>EMI: {emi} </p>
      <button onClick={calculate}>Calculate</button>

      <h3>Extra Payments</h3>
      <input
        type="number"
        placeholder="Enter extra payment"
        value={replicateAmount}
        onChange={handleReplicateAmountChange}
      />
      <input
        type="checkbox"
        checked={replicateExtra}
        onChange={handleReplicateExtraChange}
      />
      Replicate for every month <br/> From 
      <input
        type="number"
        min="1"
        value={startDate}
        onChange={(e) => handleDateChange(e, 'start')}
      />
       To 
       <input
        type="number"
        min="1"
        value={endDate}
        onChange={(e) => handleDateChange(e, 'end')}
      />

      <h3>Distribution of EMI</h3>
      <div style={{ width: '300px', height: '300px' }}>
        <Pie data={pieData} />
      </div>

      <h3>Installment Details</h3>
      <p>Loan tenure reduced by: {reducedMonths} month(s)</p>
      <table className="installment-table">
        <thead>
          <tr>
            <th>Sum</th>
            <th><i>{sums.totalEmi}</i></th>
            <th><i>{sums.totalPrincipal}</i></th>
            <th><i>{sums.totalInterest}</i></th>
            <th><i>{sums.totalExtra}</i></th>
            <th><i>-</i></th>
          </tr>
          <tr>
            <th>Month</th>
            <th>EMI(₹)</th>
            <th>Principal(₹)</th>
            <th>Interest(₹)</th>
            <th>Extra Payment(₹)</th>
            <th>Balance(₹)</th>
          </tr>
        </thead>
        <tbody>
          {installments.map((installment, index) => (
            <tr key={index}>
              <td>{installment.month}</td>
              <td>{installment.emi}</td>
              <td>{installment.principal}</td>
              <td>{installment.interest}</td>
              <td>
                <input
                  type="number"
                  value={extraPayments[index]}
                  onChange={(e) => handleChangeExtraPayment(index, e.target.value)}
                  disabled={replicateExtra}
                />
              </td>
              <td>{installment.balance}</td>
            </tr>
          ))}
        </tbody>
      </table>

      
    </div>
  );
}

export default EmiCalculator;
