import logo from './logo.svg';
import './App.css';
import EmiCalculator from './emi-calculator/EmiCalculator'
function App() {
  return (
    <div className="App">
      <EmiCalculator/>
    </div>
  );
}

export default App;
